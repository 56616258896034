/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Style
import './Button.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const Button = (props) => {
  /* ********************************** LOGIC ********************************* */

  // Props
  const {
    children,
    type = 'button',
    onClick = null,
    isDisabled = false,
    disableMarginBottom = false,
    iconSrc = '',
    isHome = false,
    modaleBTN = '',
  } = props;

  // Style
  const classes = classNames(
    // Base classes
    'button',

    // Additional classes
    isDisabled && 'button--disabled' /* This class is used to get a disabled button style */,
    disableMarginBottom && 'button--no-margin-bottom' /* This class is used to remove margin bottom */,
    type === 'submit' && 'button--submit' /* This class is used to get a sumbit button style */,
    isHome && 'button_home',
    modaleBTN && 'modal_btn'
  );

  /* ******************************** RENDERING ******************************* */

  return (
    <button className={classes} type={type === 'submit' ? 'submit' : 'button'} onClick={onClick} disabled={isDisabled}>
      <div className="button__content">
        {children}
        {iconSrc && <img className="button__content__icon" src={iconSrc} alt="Chevron" />}
      </div>
    </button>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    Props                                   */
/* -------------------------------------------------------------------------- */

Button.propTypes = {
  /**
   * The content to be rendered inside the button.
   */
  children: PropTypes.node,
  /**
   * The type of the button. Use 'button' for a regular button or 'submit' for a submit button.
   */
  type: PropTypes.oneOf(['button', 'submit']),
  /**
   * The function to be called when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Specifies whether the button is disabled.
   */
  isDisabled: PropTypes.bool,
  /**
   * Should the button have margin-bottom disabled?
   */
  disableMarginBottom: PropTypes.bool,
  /**
   * The source URL for the button icon.
   */
  iconSrc: PropTypes.string,
};

Button.defaultProps = {
  children: undefined,
  type: 'button',
  onClick: null,
  isDisabled: false,
  disableMarginBottom: false,
  iconSrc: '',
};

export default Button;
