/* eslint-disable import/no-unresolved */
/* eslint-disable prettier/prettier */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import axios from 'axios';
import environment from '../../configs/environment.configs';

// configs

/* -------------------------------------------------------------------------- */
/*                                  Services                                  */
/* -------------------------------------------------------------------------- */

class IpServices {
  static get = async () => {
    const { data: { data: { ip, countryCode } } } = await axios.get(
      `${environment.trustPlaceApi.baseUrl}/detect-country`
    );

    return { ip, countryCode };
  };
}

export default IpServices;
