const breakpoints = {
  // Mobile
  smMobile: 340, // Small
  mdMobile: 376, // Medium
  lspMobile: '@media (orientation: landscape) and (max-height: 688px)' /* Landscape : This breakpoint is passed 
                                                                          as a media query string because it's
                                                                           a custom breakpoint */,

  // Tablet
  tablet: 689,

  // Desktop
  smDesktop: 993, // Small
  mdDesktop: 993, // Medium
  lgDesktop: 1450, // Large
  xlDesktop: 1700, // Extra-large
};

export default breakpoints;
