/* eslint-disable camelcase */
import ReactGA from 'react-ga4';

const trackPageView = (page_title) => {
  ReactGA.send({
    hitType: 'pageview',
    page_title,
  });
};

const trackButtonClick = (action) => {
  ReactGA.event({
    category: 'Button',
    action,
  });
};

const trackError = (reference) => {
  ReactGA.event({
    category: 'Error',
    action: 'error_uid',
    label: reference,
  });
};

const trackForm = (fields) => {
  ReactGA.event({
    category: 'Form',
    action: 'form_canceled',
    label: `Filled Fields: ${fields.join(', ')}`,
    value: fields.length || 0,
  });
};
export { trackPageView, trackButtonClick, trackError, trackForm };
