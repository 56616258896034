// Configs
import environment from './environment.configs';

const brand = {
  key: 'isabelMarant',
  accessKey: environment.trustPlaceApi.accessKey,
  name: 'Isabel Marant',
  url: 'https://www.isabelmarant.com',
  webSite: ' isabelmarant.com ',
  faq: 'https://www.isabelmarant.com/us/customercare/faq',
  privacyPolicyUrl: 'https://www.isabelmarant.com/fr/Help/LegalArea/PrivacyPolicy',
  cookiePolicy: 'https://www.isabelmarant.com/fr/Help/LegalArea/CookiePolicy',
  useTermUrl: 'https://www.isabelmarant.com/fr/Help/LegalArea/UseTerms',
  contactUsUrl: 'https://www.isabelmarant.com/us/customercare/contact-us',
  garantePrivacyUrl: 'https://www.garanteprivacy.it/',
  EuropeaNUnionUrl: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32016R0679',
  features: {
    withUidCheck: true,
    hasShowCaseLink: true,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988417/certificate-request-page/brands/Isabel%20Marant/logo.png',
    homePageMobile:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1706777314/certificate-request-page/brands/Isabel%20Marant/home-page-image_bleemg.jpg',
    homePageDesktop:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1710762308/certificate-request-page/brands/Isabel%20Marant/home-page-image-dektop_lftonj.jpg',
    homePageMobileCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1706535796/certificate-request-page/brands/Isabel%20Marant/home-page-image-mobile-compressed_ffznrd.jpg',
    homePageDesktopCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1710762509/certificate-request-page/brands/Isabel%20Marant/home-page-image-dektop-compressed_ytsvc8.jpg',
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988524/certificate-request-page/brands/Isabel%20Marant/certificate.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988621/certificate-request-page/brands/Isabel%20Marant/compressed-certificate.png',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690988402/certificate-request-page/brands/Isabel%20Marant/favicon.ico',
    uidExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690995520/certificate-request-page/brands/Isabel%20Marant/uid-check.png',
  },
};

export default brand;
