const fonts = [
  {
    '@font-face': {
      fontFamily: 'primary',
      src: `url('https://res.cloudinary.com/dnkj85yan/raw/upload/v1674116195/fonts/UniversLTPro-BoldExt_xqrisa.otf') format("truetype")`,
      fontWeight: 'bold',
    },
  },
  {
    '@font-face': {
      fontFamily: 'secondary',
      src: `url('https://res.cloudinary.com/dnkj85yan/raw/upload/v1674116190/fonts/UniversLTPro-Ex_lfv2zj.ttf') format("truetype")`,
      fontWeight: 'normal',
    },
  },
];

export default fonts;
